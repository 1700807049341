
// import "bootstrap/dist/css/bootstrap.min.css"
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button';
// import { Link } from 'react-router-dom';
import ContactStyle from './ContactUs.module.css'
import BgImage from '../../../Images/Contact-Us.jpg'
import {toast, ToastContainer} from 'react-toastify'



import { useState } from 'react';
import axios from 'axios'


function ContactUs(){
    const [fullname, setFullname] = useState("")
    const [phone, setPhone] = useState("")
    const [email, setEmail] = useState("")
    const [description, setDescription] = useState("")
    const [error, setError] = useState(false)
    const [loading, setLoading] = useState(false)


    function hompage (){
      return window.location = '/'
     }
 
     function btnloading(){
       setLoading(true)
       // Button.disabled(true)
     }
     function btnunload(){
       setLoading(false)
       //  Button.disabled(true)
     }


    const handleSubmit = async (e) =>{
        e.preventDefault()
        setError(false)
        console.log(e)
        if(!fullname || !phone || !email){
             
          return toast.error("Please fill all the input boxes correctly", setTimeout(btnloading, 100) , setTimeout(btnunload, 7000))
        }
        
        try{
            const data = await axios.post("https://my-learning-site.cyclic.app/api/contact", {
            // const res = await axios.post("http://localhost:5000/api/contact", {
                fullname,
                phone,
                email,
                description   
            })
           
            setLoading(false);
            toast.success('Thank you for contacting ekitech software engineering compny, the admin will get back to you soon!.', setTimeout(btnloading, 100) ,setTimeout(hompage, 6000))
           
           
            
        } catch(err) {
            setError(true)
           
        }

        
    }
   
    return(

      
       
        <div className={ContactStyle.main}>
          <ToastContainer position = "top-right" limit ={1}/>
           <div className={ContactStyle.appImg}>
          <img src={BgImage} alt="" />
          </div>
            <form className={ContactStyle.contactForm} onSubmit={handleSubmit}>
            <div className={ContactStyle.formcontent}>

            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                <Form.Control type ="text" 
                className="LoginInput" 
                placeholder="Enter your fullname here..."
                onChange={e=>setFullname(e.target.value)} />
              </Form.Group>

              <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                <Form.Control type ="tel" 
                className="LoginInput" 
                placeholder="Enter your phone number here..."
                onChange={e=>setPhone(e.target.value)} />
              </Form.Group>
                
              <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                {/* <label>Email</label> */}
                <Form.Control type ="email" 
                className="LoginInput" 
                placeholder="Enter your email here..." 
                onChange={e=>setEmail(e.target.value)} />
              </Form.Group>

              <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                <Form.Control as="textarea" rows={3}
                className={ContactStyle.textArea} placeholder="Description..." onChange={e=>setDescription(e.target.value)}/>
                </Form.Group>


                <div className="d-grid gap-2">
                    <Button variant="primary" size="lg" type="submit" disabled ={loading} >
                      Send
                    </Button>
                </div>


                {/* <button className="loginButton" type='submit'>Register</button> */}
                {error &&<span style={{color:"red", marginTop:"10px", fontSize:"20px"}}>Something went wrong!</span>}
                </div>
            </form>
           

            

        </div>
       
        
    )
}

export default ContactUs;