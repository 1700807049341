import React from 'react';
import './App.css';
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom'
// import Section from './PriceListComponent/Body/Section/Section';
import FullPage from "./PriceListComponent/FullPage/FullPage"
import Header from './PriceListComponent/Header/newHeader/Header';
// import Header from "./PriceListComponent/Header/Header"
import Introduction from './PriceListComponent/Body/intro/Introduction';
import Cards from './PriceListComponent/Cards/Cards';
import Basic from './PriceListComponent/forms/Basic/Basic';
import Pro from './PriceListComponent/forms/Pro'
import Premium from './PriceListComponent/forms/Premium';
import Footer from './PriceListComponent/Footer/Footer';
import Condition from './PriceListComponent/Body/Section/Condition';
import Successful from './PriceListComponent/forms/Successful';
import Learning from './PriceListComponent/Body/learningSection/learning';
import Partner from './PriceListComponent/Body/AboutUs/AboutUs';
import HomePage from './PriceListComponent/Body/LandingPage/HomePage';
import Application from './PriceListComponent/forms/Application/Dbase/ApplicationForm';
import Hiredev from './PriceListComponent/forms/HireDev/Hiredev';
import HomeTraining from './PriceListComponent/forms/HomeTraining/HomeTraining';
import Professionals from "./PriceListComponent/Professionals/Professionals"

import ContactUs from './PriceListComponent/forms/ContactUs/ContactUs';
import SignUp from './PriceListComponent/Cards/SignUps';
import BioScreen from './PriceListComponent/FullBio/BioScreen';
import GetWebSiteForm from './PriceListComponent/forms/WebForm/requestForWebsite';
import WebsitePrice from './PriceListComponent/WebsitePrice/WebsitePrice';
import MissingPage from './PriceListComponent/MissingPage/MissingPage';


import {useState, useEffect} from 'react'
import ProfessionalParent from './PriceListComponent/Professionals/ProfessionalParent';
import AboutUs from './PriceListComponent/Body/AboutUs/AboutUs';





function App() {

  const [users, setUsers] = useState([
    {
      id: 1,
      title: "My First Post",
      datetime: "July 01, 2021 11:17:36 AM",
      body: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Quis consequatur expedita, assumenda similique non optio! Modi nesciunt excepturi corrupti atque blanditiis quo nobis, non optio quae possimus illum exercitationem ipsa!",
      img:"image here"
    },
    {
      id: 2,
      title: "My 2nd Post",
      datetime: "July 01, 2021 11:17:36 AM",
      body: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Quis consequatur expedita, assumenda similique non optio! Modi nesciunt excepturi corrupti atque blanditiis quo nobis, non optio quae possimus illum exercitationem ipsa!",
      img:"image here"
    },
    {
      id: 3,
      title: "My 3rd Post",
      datetime: "July 01, 2021 11:17:36 AM",
      body: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Quis consequatur expedita, assumenda similique non optio! Modi nesciunt excepturi corrupti atque blanditiis quo nobis, non optio quae possimus illum exercitationem ipsa!",
      img:"image here"
    },
    {
      id: 4,
      title: "My Fourth Post",
      datetime: "July 01, 2021 11:17:36 AM",
      body: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Quis consequatur expedita, assumenda similique non optio! Modi nesciunt excepturi corrupti atque blanditiis quo nobis, non optio quae possimus illum exercitationem ipsa!",
       img:"image here"
    }
  ])

  const [usersTitle, setUsersTitle] = useState('');
  const [usersBody, setUsersBody] = useState('');
  // const history = useHistory();




  return (
   
   
    <div className="App">
      <Router>
       
      <Header />
        <Routes>
          <Route exact path='/' element={<HomePage />}></Route>
          <Route exact path='/aboutus' element={<AboutUs />}></Route>

          {/* <Route path='/cards' element={<Cards />}></Route> */}
         <Route path='/application' element={<Application />}></Route>
         


        <Route path='/hiredev' element={<Hiredev />}>

        </Route>

        <Route path='/hometraining' element={<HomeTraining />}>

        </Route>

        <Route path='/professionals' element={<Professionals />}></Route>
        {/* <Route path='/professionals' element={<ProfessionalParent />}>
        <ProfessionalParent users = {users} />
        </Route> */}

        <Route path='/professionals/:id' element={<BioScreen />}>

        </Route>

        <Route path='/contactus' element={<ContactUs />}>

        </Route>

        <Route path='/getwebsite' element={<GetWebSiteForm />}>

        </Route>

        <Route path='/website_price' element={<WebsitePrice />}>

        </Route>

        
        <Route path='/signup' element={<SignUp />}></Route>
        <Route path='*' element={<MissingPage />}></Route>
      </Routes>
      </Router>
      <Footer />
     
    </div>

   
  );
}

export default App;
