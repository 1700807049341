

const SignUp = ()=>{
    return(
        <div>
            <h1>PLEASE CONTACT ADMIN ON: 08107914902</h1>
        </div>
    )
}

export default SignUp;