import React from 'react'
import AboutStyle from './AboutUs.module.css'
import {Link} from 'react-router-dom'

const AboutUs = () => {
  return (
    <main className={AboutStyle.mainContainer}>
      <article className={AboutStyle.articleContainer}>
        <div className={AboutStyle.description}>
          <h1>ABOUT US</h1>
              <h2>Ekitech is one of the best software engineering company in Nigeria that is helping individuals, schools, oganizations and government agencies to actualize their dreams in tech. We are a team of talented professionals that want to help young Africans grow as we're growing and expand the ecosystem to the next generation of talents. 
                
              </h2>
        </div>
      
        <div className={AboutStyle.vission_mission_Container}>

          <div className={AboutStyle.vission}>
            
          <p>VISSION</p>
            
              <p>
                To create an enabling environment where businesses will be thrieving seamlessly and make Nigeria number one  tech hub in Africa. 
              </p>
             
          </div>
        
          <ul className={AboutStyle.missionContainer}>
          <p>MISSION</p> 
            <li className={AboutStyle.mission}>We are on a mission to train Nigerian youths, students, graduates, and organizations on Software Engineering, Artificial Intelligence, Nework Security, Internet of Things, Cloud Engineering and, DevOps Engineering.
            </li>
            <li>
            To creating an enabling environment where businesses will be thrieving online and attracts potential clients by building scalable websites, e-commerce platforms, portals and other customize scalable apps to help business owners, organisations on their daily business operation. If that sounds interesting click <span><Link to='/website_price'><cite>here</cite></Link></span>
            </li>
            
          </ul> 
        </div>

        
        <div className={AboutStyle.corevalue}>
            <p>OUR CORE VALUE<p>Your Satisfaction is Our Pride</p></p>
        </div>

        <div className={AboutStyle.missionpartaker}>
          <p>BE PART OF OUR VISSION AND MISSION  <p>You can be part of our vission and mission by supporting individual, group of people, students of any level and graduates by investing on their career path and we will train them on all the neccessary technical and non-technical skills, practicals and live projects. If that sounds interesting, let's talk <span><Link to='/contactus'><cite>here</cite></Link></span> 
          </p></p>
          
        </div>
      
      </article>
    </main>
  )
}

export default AboutUs