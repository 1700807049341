import React from 'react'
import  './successful.css'
import Fade from 'react-reveal/Zoom'

function Successful() {
  return (
      <Fade cascade >

    <div className='background'>
        <img src='images/success.png' alt='Background Image' ></img>

    </div>
    </Fade>
  )
}

export default Successful