

import { useFormik } from "formik";
import { Link } from 'react-router-dom';
import ApplicationStyle from './HomeTraining.module.css'
import BgImage from "../../../Images/edit.jpg"
import Fade from 'react-reveal/Zoom'
import {useState} from 'react'

function HomeTraining(){
  const [fullname, setFullname] = useState("")
  const [phone, setPhone] = useState("")
  const [email, setEmail] = useState("")
  const [tract, setTract] = useState("")
  const [duration, setDuration] = useState("")
  const [description, setDescription] = useState("")
  const [error, setError] = useState(false)




    const initialValues = {
      name:"",
      email:"",
      phone:'',
      package:'',
      description:""
    }

const onSubmit = values => {

  const handleHomeFormSubmit =() =>{

  }
 
 

  return (
    <Fade cascade>
    <div className={ApplicationStyle.main}>
      <h1>The Admin is working at the backend Contact Admin for further step</h1>
    {/* <img src={BgImage} alt="" />
    <div className={ApplicationStyle.txt}>Fill the application form correctly </div>
     
       <form onSubmit={handleHomeFormSubmit}>
        <div className={ApplicationStyle.formcontent}>

      <div className={ApplicationStyle.formControl}>
      <label htmlFor="name"></label>
      <input type="text" id="name" name="name" placeholder='Full name ...' />
      </div>

      <div className={ApplicationStyle.formControl}>
      <label htmlFor="name"></label>
      <input type="text" id="name" name="name" placeholder='Your location ...' />
      </div>

      <div className={ApplicationStyle.formControl}>
      <label htmlFor="phone"></label>
      <input type="tel" id="phone" name="phone" placeholder='Phone number...' />
      </div>


      <div className={ApplicationStyle.formControl}>
      <label htmlFor="email"></label>
      <input type="email" id="email" name="email" placeholder='Email address...'  />
      </div>

      <div className={ApplicationStyle.formControl}>
      <label htmlFor="homeaddress"></label>
      <input type="text" id="homeaddress" name="address" placeholder='Home address...' />
      </div>


     
      <div className={ApplicationStyle.formControl}>
      <label htmlFor="channel"></label>
      <select name='plans' id='plan'>
        <option > Choose your package</option>
        <option value="html">HTML</option>
        <option value="styling">CSS</option>
        <option value="jscript">JavaScript</option>
        <option value="nodejs">NodeJS</option>
        <option value = 'frontend'>Frontend</option>
        <option value = 'backend'>Backend</option>
        <option value = 'fulstack'>Fullstack</option>
        <option value = 'desktop'>Desktop App.</option>
        <option value = 'other' >Others</option>
        </select>
      </div>

      <div className={ApplicationStyle.formControl}>
      <label htmlFor="channel"></label>
      <select name='plans' id='plan'>
        <option > Duration</option>
        <option value = 'fulltime'>Two Months</option>
        <option value = 'parttime'>Three Months</option>
        <option value = 'contract'>Four Months</option>
        <option value = 'contract'>Five Months</option>
        <option value = 'contract'>Six Months</option>
        <option value = 'contract'>Seven Months</option>
        <option value = 'contract'>Eight Months</option>
        <option value = 'contract'>Nine Months</option>
        <option value = 'contract'>Ten Months</option>
        <option value = 'contract'>One Year</option>
        
       </select>
      </div>

      

      <div className={ApplicationStyle.formControl}>
      <label htmlFor="description"></label>
      <textarea type="text" id="description" rows='10'  cols='30' name="channel" placeholder='Dscription... ' />
      </div>

      <div className={ApplicationStyle.button}> <Link to='/successful'>
      <button type="submit">Submit</button>
      </Link>
      </div>
      </div>

     
</form> */}

    </div>
    </Fade>
  )
}
}
export default HomeTraining;