import React from 'react'
import { useState } from 'react'
import { Link } from 'react-router-dom';
import {useNavigate}from "react-router";



import './Header.css'

const Header = () => {
    let navigate = useNavigate();
    function website_Price_Page() {
    navigate ('/website_price')
    }
    const [isMobil, setIsMobile] = useState(false)
    return (
        <div className='navbar'>
                 <div className='logo'>
                   
                    {/* <a href = "/" className="logoPoint">EKiTECH</a> */}
                    <p className="logoPoint"><Link to='/'>EKiTECH</Link></p>
                   
                </div>
                <div  className={isMobil ? "navLinkMobile" : 'navlink'} 
                onClick={()=> setIsMobile(false)}
                > 
                    {/* <a href="/" className='contact'>Home</a> */}
                    <p className="contact"><Link to='/'>Home</Link></p>
                     {/* <a href = "/"  className='contact'>About Us</a> */}
                     <p className="contact"><Link to='/aboutus'>About us</Link></p>

                    <a  className='contact service'>Our Services
                    {/* <p className="contact service">Our Services */}
                                <span>
                                    <i className='bx bxs-chevron-right major-arrow more-arrow'></i>
                                </span>
                    
                    <ul className='servcieSubMenu subMenu'>
                        <li><Link to='/website_price'>Website Development</Link></li>
                        {/* <p className="li a"><Link to='/cards'>Website Development</Link></p> */}
                        
                        <li><Link to="/website_price">Software Development</Link></li>
                        {/* <p className="li a"><Link to='/website_price'>Software Development</Link></p> */}
                            
                        
                        
                        <li><Link to>Digital Marketing</Link></li>
                        {/* <p className="li a"><Link to='/'>Digital Marketing</Link></p>  */}

                        <li><Link to>SEO Optimization</Link></li>
                        {/* <p className="li a"><Link to='/'>SEO Optimization</Link></p> */}

                        <li><Link to ='/hiredev'>Software Engineers Recruitment</Link></li>
                        {/* <p className="li a"><Link to='/'>Software Engineering Recruitment</Link></p> */}

                                 
                        <li><Link to ='/contactus'>ICT Staff Recruitment</Link></li>
                        {/* <p className="li a"><Link to='/'>ICT Staff Recruitment</Link></p> */}

                        <li><Link to='/contactus'>ICT Consultant</Link></li>
                        {/* <p className="li a"><Link to='/'>ICT Consultant</Link></p> */}

                        <li className="more">
                                <Link to>Software Engineering Training</Link>
                                {/* <p className="li a"><Link to='/'>Software Engineering Training</Link></p> */}
                                <span>
                                    <i className='bx bxs-chevron-right arrow more-arrow'></i>
                                </span>
                                    <ul className="more-sub-menu sub-menu">
                                        <li><Link to >Online Class</Link></li>
                                        {/* <p className="li a"><Link to='/'>Online Class</Link></p> */}

                                        <li><Link to>Onsite Class</Link></li>
                                        {/* <p className="li a"><Link to='/'>Onsite Class</Link></p> */}

                                        <li><Link to>Home Training</Link></li>
                                        {/* <p className="li a"><Link to='/'>Home Training</Link></p> */}
                                    </ul>
                            </li>           
                    </ul>
                    {/* </p> */}
                    </a>
                    {/* <a href ="/professionals" className='contact'>Professionals</a> */}
                    <p className="contact"><Link to='/professionals'>Professionals</Link></p>

                   

                    {/* <a href = "/contactus" className='contact'>Contact Us</a> */}
                    <p className="contact"><Link to='/contactus'>Contact Us</Link></p>
                    {/* <a href = "tel:08107914902" className='contact'>Call Us Now</a> */}
                   
                    
                    
                </div>
                <button className='mobileMenu'
                onClick={() => setIsMobile(!isMobil)}
                >
                    {isMobil ? 
                    ( <i className= "fas fa-times"></i>
                    ):(
                        <i className= "fas fa-bars"></i>
                        )
                        }
                </button>
           
            





        </div>
       
    )
}

export default Header