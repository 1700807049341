
// import "bootstrap/dist/css/bootstrap.min.css"
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button';

// import { useFormik } from "formik";
// import { Link } from 'react-router-dom';
import ApplicationStyle from './applicationform.module.css'
// import BgImage from '../../../../Images/edit.jpg'
// import Fade from 'react-reveal/Zoom'


import { useState } from 'react';
import axios from 'axios'
import {toast, ToastContainer} from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';



function Registration(){
    const [fullname, setFullname] = useState("");
    const [phone, setPhone] = useState("");
    const [email, setEmail] = useState("");
    const [tract, setTract] = useState("");
    const [duration, setDuration] = useState("");
    const [experience, setExperience] = useState("");
    const [learninglocation, setLearningLocation] = useState("");
    const [description, setDescription] = useState("");
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false);


    function hompage (){
     return window.location = '/'
    }

    function btnloading(){
      setLoading(true)
      // Button.disabled(true)
    }
    function btnunload(){
      setLoading(false)
      //  Button.disabled(true)
    }
   


    const handleSubmit = async (e) =>{
        e.preventDefault()
        setError(false)
        console.log(e)
        if(!fullname || !phone || !email){
             
          return toast.error("Please fill all the input boxes correctly", setTimeout(btnloading, 100) , setTimeout(btnunload, 7000))
        }
        
        try{
            setLoading(true)
            const data = await axios.post("https://my-learning-site.cyclic.app/api/registration", {
            // const data = await axios.post("http://localhost:5000/api/registration", {
                fullname,
                phone,
                email,
                tract,
                duration,
                experience,
                learninglocation,
                description   
            })
            
           
              setLoading(false);
              toast.success('You submission is successful, the admin will contact you soon.', setTimeout(btnloading, 100) ,setTimeout(hompage, 6000))
              // toast.success(data.message)
            
            
           

           
           
        } catch(err) {
          // setLoading(toast.error("Please make sure that you have fill all the input boxes. If the prblem persist call the admin"),setTimeout(btnloading, 100) , setTimeout(btnunload, 7000))
          // toast.err("Please make sure that you have fill all the input boxes. If the prblem persist call the admin")
         
          // toast.error(
          //   err.response && err.response.data.message
          //   ? err.response.data.message
          //   : err.message
          // )
            setError(true)
           
        }

        
    }
   
    const handlesetTract = (e) =>{
      e.preventDefault()
      console.log(e.target.value)
      setTract(e.target.value)
    }


    return(

      
       
        <div className={ApplicationStyle.main}>
          <ToastContainer position = "bottom-center" limit ={1}/>

          <div className={ApplicationStyle.appImg}></div>
            <form className={ApplicationStyle.applicationForm} onSubmit={handleSubmit}>
            <div className={ApplicationStyle.formcontent}>

            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                <Form.Control type ="text" 
                className="LoginInput" 
                placeholder="Enter your fullname here..."
                onChange={e=>setFullname(e.target.value)} />
              </Form.Group>

              <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                <Form.Control type ="tel" 
                className="LoginInput" 
                placeholder="Enter your phone number here..."
                onChange={e=>setPhone(e.target.value)} />
              </Form.Group>
                
              <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                {/* <label>Email</label> */}
                <Form.Control type ="email" 
                className="LoginInput" 
                placeholder="Enter your email here..." 
                onChange={e=>setEmail(e.target.value)} />
              </Form.Group>

              <div className={ApplicationStyle.formControl}>
                <label htmlFor="channel"></label>
                <Form.Select size="lg" className='plans' id='plan' onChange={e=>handlesetTract(e)} className={ApplicationStyle.selectGrayFont}>
                {/* <option value="default" disabled selected hidden className={ApplicationStyle.selectGrayFont} placeholder="choose" >Choose your package </option> */}
                <option value="default" disabled selected hidden  >Choose your stack</option>
                <option value='Frontend'  >Frontend</option>
                <option value='Backend'  >Backend</option>
                <option value='fulstack' >Fullstack</option>
                <option value='desktop' >Desktop App.</option>
                <option value='other' >Others</option>
              </Form.Select>
              </div>

              <div className={ApplicationStyle.formControl}>
                <label htmlFor="channel"></label>
                <Form.Select size="lg" className='plans' id='plan' onChange={e=>setDuration(e.target.value)} className={ApplicationStyle.selectGrayFont}>
                <option value="default" disabled selected hidden > Duration</option>
                <option value = 'One Month'>One Month</option>
                <option value = 'Two Months'>Two Months</option>
                <option value = 'Three Months'>Three Months</option>
                <option value = 'Four Months'>Four Months</option>
                <option value = 'Five Months'>Five Months</option>
                <option value = 'Six Months'>Six Months</option>
                <option value = 'Seven Months'>Seven Months</option>
                <option value = 'Eight Months'>Eight Months</option>
                <option value = 'Nine Months'>Nine Months</option>
                <option value = 'Ten Months'>Ten Months</option>
                <option value = 'One Year'>One Year</option>
              </Form.Select>
              </div>

              <div className={ApplicationStyle.formControl}>
                <label htmlFor="channel"></label>
                <Form.Select size="lg" className='plans' id='plan' onChange={e=>setExperience(e.target.value)}
                className={ApplicationStyle.selectGrayFont}
                >
                <option  value="default" disabled selected hidden> Your experience in programming</option>
                <option value='novice'  >Novice</option>
                <option value='beginner'  >Beginner</option>
                <option value='intermediate' >Intermediate</option>
                <option value='professional' >Professional</option>
                <option value='advance' >Advance</option>
              </Form.Select>
              </div>


              <div className={ApplicationStyle.formControl}>
                <label htmlFor="channel"></label>
                <Form.Select size="lg" className='plans' id='plan' onChange={e=>setLearningLocation(e.target.value)}
                className={ApplicationStyle.selectGrayFont}
                >
                <option  value="default" disabled selected hidden>Prefer location</option>
                <option value='novice'  >Online class</option>
                <option value='beginner'  >Physical class</option>
                <option value='intermediate' >Home training class</option>
                
              </Form.Select>
              </div>

              

              <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                <Form.Control as="textarea" rows={3} className={ApplicationStyle.textArea} placeholder="Enter your fullname here..." onChange={e=>setDescription(e.target.value)}/>
                </Form.Group>


                {/* <div className="d-grid gap-2">
                <Button variant="primary" size="lg" type="submit" disabled ={loading}>
                  {loading ? "Sending..." : "Register"}
                </Button>
                 </div> */}
                <div className="d-grid gap-2">
                <Button variant="primary" size="lg" type="submit" disabled ={loading} >
                  Register
                </Button>
                 </div>

               

                {/* <button className="loginButton" type='submit'>Register</button> */}
                {error &&<span style={{color:"red", marginTop:"10px", fontSize:"20px"}}>Something went wrong!</span>}
                </div>
            </form>
           

            {/* <Link to="/login"  >
            <button className="registerLoginButton" >Login</button>
            </Link> */}

        </div>
       
        
    )
}

export default Registration;