import React from 'react'
import FooterStyle from "./Footer.module.css"
const Footer = () => {
    const today = new Date()
    return (
        <div className= {FooterStyle.footer}>
            <div className={FooterStyle.lastcontent}>
            <div className ={FooterStyle.addressbar}>
                    <address>
                        <h3>Head Office </h3>
                       <p> The Cans,   {" "}Amusement Park, Maitama Abuja.</p>
                        <div> <h4>Learning Centers</h4>
                        <p>Kemtos ICT,  Ado Junction.</p>
                        <p>Matissa Techcom, Kugbaru Road Ado.</p>
                        </div>
                    </address>
                        
                        <div className={FooterStyle.colzicon}>
                            <h4>Follow Us On</h4>
                        
                        
                        <a href='https://web.facebook.com/?_rdc=1&_rdr'>
                            <i className='fa fa-facebook-square'></i>
                        </a>
                        <a href='https://www.linkedin.com/in/ekemini-ben/'>
                            <i className='fa fa-linkedin'></i>
                        </a>
                        <a href='https://twitter.com/EkeminiBen3'>
                            <i className='fa fa-twitter'></i>
                        </a>
                        <p>+2348107914902</p>
                        <div className='emailStyling'>
                        <a href='mailto:ekemiben.4@gmail.com'>
                            <i className=' fa-email'>ekitechsoftware@gmail.com</i>
                        </a>
                        
                        </div>
                        </div>


                 </div>
                    
                   
                   

           
           

           

                 <p>All Rights Reserved  &copy;{today.getFullYear()}</p>
           
          
       </div>
        </div>
    )
}

export default Footer
