import MissingStyle from './Missing.module.css'
import React from 'react'
import {Link} from 'react-router-dom'

function MissingPage() {
  return (
    <main className={MissingStyle.main}>
      <div className={MissingStyle.textContainer}>
      <h1> Oops! sorry you entered a wrong Url. Please click <span><Link to='/'><cite>here</cite></Link></span>  to go back to the landing page</h1>
      </div>
      
    </main>
  )
}

export default MissingPage