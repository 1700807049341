
// import "bootstrap/dist/css/bootstrap.min.css"
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button';

// import { useFormik } from "formik";
// import { Link } from 'react-router-dom';
import GetwebsiteStyle from './requestForWebsite.module.css'
// import BgImage from '../../../Images/requestWebsite.jpg'
import BgImage from '../../../Images/CourseBG1.jpg'
// import BgImage from '../../../Images/MuchaTseBle.jpg'
// import Fade from 'react-reveal/Zoom'


import { useState } from 'react';
import axios from 'axios'
import {toast, ToastContainer} from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';



function GetWebSiteForm(){
    const [fullname, setFullname] = useState("")
    const [phone, setPhone] = useState("")
    const [email, setEmail] = useState("")
    const [clientchoice, setclientChoice] = useState("")
   
    const [description, setDescription] = useState("")
    const [error, setError] = useState(false)
    const [loading, setLoading] = useState(false)


    function hompage (){
     return window.location = '/'
    }

    function btnloading(){
      setLoading(true)
      // Button.disabled(true)
    }
    function btnunload(){
      setLoading(false)
      //  Button.disabled(true)
    }
   


    const handleSubmit = async (e) =>{
        e.preventDefault()
        setError(false)
        console.log(e)
        if(!fullname || !phone || !email){
             
          return toast.error("Please fill all the input boxes correctly", setTimeout(btnloading, 100) , setTimeout(btnunload, 7000))
        }
        
        try{
            setLoading(true)
            const data = await axios.post("https://my-learning-site.cyclic.app/api/getwebsite", {
            // const data = await axios.post("http://localhost:5000/api/getwebsite", {
                fullname,
                phone,
                email,
                clientchoice,
                description   
            })
            
           
              setLoading(false);
              toast.success('Your request is recieved! the admin will contact you soon.', setTimeout(btnloading, 100) ,setTimeout(hompage, 6000))
              // toast.success(data.message)
            
            
           

           
           
        } catch(err) {
          // setLoading(toast.error("Please make sure that you have fill all the input boxes. If the prblem persist call the admin"),setTimeout(btnloading, 100) , setTimeout(btnunload, 7000))
          // toast.err("Please make sure that you have fill all the input boxes. If the prblem persist call the admin")
         
          // toast.error(
          //   err.response && err.response.data.message
          //   ? err.response.data.message
          //   : err.message
          // )
            setError(true)
           
        }

        
    }
   
    const handleSelectPackage = (e) =>{
      e.preventDefault()
      console.log(e.target.value)
      setclientChoice(e.target.value)
    }


    return(

      
       
        <div className={GetwebsiteStyle.main}>
          <ToastContainer position = "top-right" limit ={1}/>

          <div className={GetwebsiteStyle.appImg}>
          <img src={BgImage} alt="" download="lazy" />
            

          </div>
            <form className={GetwebsiteStyle.applicationForm} onSubmit={handleSubmit}>
              <div className={GetwebsiteStyle.formcontent}>

                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                    <Form.Control type ="text" 
                    className="LoginInput" 
                    placeholder="Enter your fullname here..."
                    onChange={e=>setFullname(e.target.value)} />
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                    <Form.Control type ="tel" 
                    className="LoginInput" 
                    placeholder="Enter your phone number here..."
                    onChange={e=>setPhone(e.target.value)} />
                  </Form.Group>
                    
                  <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                    {/* <label>Email</label> */}
                    <Form.Control type ="email" 
                    className="LoginInput" 
                    placeholder="Enter your email here..." 
                    onChange={e=>setEmail(e.target.value)} />
                  </Form.Group>

                  <div className={GetwebsiteStyle.formControl}>
                    <label htmlFor="channel"></label>
                    <Form.Select size="lg" className='plans' id='plan' onChange={e=>handleSelectPackage(e)} className={GetwebsiteStyle.selectGrayFont}>
                    {/* <option value="default" disabled selected hidden className={GetwebsiteStyle.selectGrayFont} placeholder="choose" >Choose your package </option> */}
                    <option value="default" disabled selected hidden  >Choose your package</option>
                    <option value='Frontend'  >Basic</option>
                    <option value='Backend'  >Pro</option>
                    <option value='fulstack' >Premium</option>
                  </Form.Select>
                  </div>

                

                  

                  

                  <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                    <Form.Control as="textarea" rows={3} className={GetwebsiteStyle.textArea} placeholder="Enter your fullname here..." onChange={e=>setDescription(e.target.value)}/>
                    </Form.Group>


                    {/* <div className="d-grid gap-2">
                    <Button variant="primary" size="lg" type="submit" disabled ={loading}>
                      {loading ? "Sending..." : "Register"}
                    </Button>
                    </div> */}
                    <div className="d-grid gap-2">
                    <Button variant="primary" size="lg" type="submit" disabled ={loading} >
                      Send
                    </Button>
                    </div>

                  

                    {/* <button className="loginButton" type='submit'>Register</button> */}
                    {error &&<span style={{color:"red", marginTop:"10px", fontSize:"20px"}}>Something went wrong!</span>}
                    </div>
                </form>
              

                {/* <Link to="/login"  >
                <button className="registerLoginButton" >Login</button>
                </Link> */}

        </div>
       
        
    )
}

export default GetWebSiteForm;