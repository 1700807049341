import {Link} from 'react-router-dom'
import LearningImg from '../../../Images/teach.jpg'
import LearningImgTwo from "../../../Images/Work from home tips.png"
import LanguageImg from '../../../Images/animone.gif'

// Learning
import ReactImg  from "../../../Images/react.png"
import JavascriptImg  from "../../../Images/JavaScriptlogo1.png"
import PythonImg  from "../../../Images/Python.jpg"
import NodeImg  from "../../../Images/node-js.png"
import FullStack  from "../../../Images/nodejs image.png"
import FrontEnd  from "../../../Images/frontend.jpg"
import BackEnd  from "../../../Images/Backend.jpeg"
import Desktop  from "../../../Images/Desktop.jpg"
import LeftImg from '../../../Images/Interview.jpg'
import Animatleft from 'react-reveal/Flip'
import Courses from 'react-reveal/Jump'



import ImgBk from "../../../Images/imgone.gif"
import Style from "./home.module.css"

function HomePage(){
    return(
        <div className={Style.overallmain}>
            {/* Animated main first background */}
            <div className={Style.animatedMain}>
                <div className={Style.headergiff}>
                    {/* <img src={ImgBk} alt="" /> */}
                </div>
                <div className={Style.careerCaption}>
                    <div className={Style.careertext}>
                    <h1>Kickstart Your Tech Career  Today!</h1>
                    </div>
                    <div className={Style.captionmessage}>
                    <p>Let us help you begin an amazing journey to becoming a professional software engineer and land your first tech job within 6 months!</p><br></br><br></br>
                    </div>

                
                    <div className={Style.btnApply} >
                        <Link to ="/application">
                        <button> Apply</button>
                        </Link> 
                        <Link to="/website_price"> 
                        <button> Get a website</button>
                        </Link>
                    </div>
                </div>
                    

            </div>
            
            {/* Learning Section */}<hr></hr>
            <div className={Style.learningbodyCover}>
                <div className={Style.reasonToJoinEkitechContainer}>
                    <div className={Style.reasonTextCaption}>
                        {/* <h1>Why Join Ekemiben Tech Community?</h1> */}
                        <h1>Why Join EKiTECH Sofware Engineering Training?</h1><br></br>
                        <p>Learning alone could be so tedious, our students collaborate with each other learn and develop real-life application before the completion of their programm. At the end of your program you will be able to build a web-based scalable application</p>
                    </div>
            
                    <div className={Style.learningMain}>
                    <Animatleft >
                    
                        <div className={Style.flexcolumn}>
                            <div className= {Style.learning}>
                                <img src={LearningImgTwo} alt="Learning pix" />
                            </div>
                            <div className={Style.belowImg}>
                            <hr></hr>
                                <p>
                                Code independently after six months of rigorouse and intensive training by team of sofware engineer experts. solidify your skills with take home project and be ready for remote/onsite job and internships
                                </p>
                            </div>
                        </div>
                        
                        <div className={Style.flexcolumn}>
                            <div className= {Style.learning}>
                                <img src={LearningImg} alt="Learning pix" />
                            </div>
                            <div className={Style.belowImg}>
                            <hr></hr>
                                <p> 
                            Enroll in six months intensive training to become a junior software engineer and have access to more than one million tech job opportunities and internships
                            </p>
                            </div>
                        </div>
                        </Animatleft>
                    </div><br></br>
                    
                </div>
{/* Last part for this component */}
            <div className={Style.programmingtracks}>
            <div className={Style.imgContainer}>
                <p>Availble Courses That Will Make You Stand Out </p>
                <Courses>
                <div className={Style.languages}>
                    <div className={Style.prog}>
                    <img src={JavascriptImg} alt="" />
                    <br></br>
                    {/* <hr></hr> */}
                       <p>JavaScript</p> 
                    </div>

                    
                    <div className={Style.prog}>
                    <img src={ReactImg} alt="" download="lazy" />
                    <br></br>
                    {/* <hr></hr>  */}
                    <p>React </p>
                    </div>


                    <div className={Style.prog}>
                    <img src={NodeImg} alt="" />
                    <br></br>
                    {/* <hr></hr> */}
                       <p> Node.Js</p>
                    </div>

                    <div className={Style.prog}>
                    <img src={PythonImg} alt="" />
                    {/* <hr></hr> */}
                    <br></br>
                    
                       <p>Python</p> 
                    </div>
                </div>


                <div className={Style.tracks}>
                    <div className={Style.prog}>
                    <img src={FrontEnd} alt="" />
                    <br></br>
                    {/* <hr></hr> */}
                       <p>Frontend</p> 
                    </div>

                    
                    <div className={Style.prog}>
                    <img src={BackEnd} alt="" />
                    <br></br>
                    {/* <hr></hr>  */}
                    <p>Backend </p>
                    </div>


                    <div className={Style.prog}>
                    <img src={FullStack} alt="" />
                    <br></br>
                    {/* <hr></hr> */}
                       <p> Fullstack</p>
                    </div>

                    <div className={Style.prog}>
                    <img src={Desktop} alt="" download = "lazy" />
                    {/* <hr></hr> */}
                    <br></br>
                    
                       <p>Desktop App</p> 
                    </div>
                   
                </div>
                <Link to="/application">
                <div className={Style.btnapply}><button>Apply</button></div>
                </Link>
                </Courses>
            </div>   
        </div>
        </div>

        {/* Partner Section */}
        <div className={Style.mottoMain}>
            <div className={Style.submain}>
                <div className={Style.mottoColumnsContainer}>
                    <div className={Style.mottoColumnOne}>
                        <img src={LeftImg} alt="" download = "lazy"/>
                        <div className={Style.homeTrainingbox}>
                            <div className={Style.hiringBtnCon}>
                                <Link to="/hiredev">
                                <button>Hire a developer</button>
                                </Link>
                            
                                
                                <Link to="/">
                                    <button>Home Training</button>
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className={Style.mottoColumnTwo}>
                        <div className={Style.mottoCaption}><h1>YOUR SUCCESS AND SATISFACTION IS OUR DRIVES</h1></div>

                        <div className={Style.homeTrainingTxt} >
                            
                                
                                    
                                    <p>  Are you not satisfy with your current job and you want to switch your career into tech  while you are still working, or you want to learn at the comfort of your home through physical tutors? </p>
                                    
                                    <p className={Style.mottoSubP}>Don't worry we got you covered, you can now learn software engineering courses with real-life practicals and projects at the comfort of your home. Just hit the home training button and the admin will communicate back to you and assign an experienced software engineer to tutor you at the comfort of your home </p>
                                     
                            
                            
                         
                         
                        </div>
                        <div className={Style.hireTxt}><p>Are you worried about getting the right software engineers, graphics designers and other ICT staff in your Organization?</p>

                        <p className={Style.mottoSubP}> If yes, you're in the right place. We know finding top tech talent is a tedious exercise. You can hire from the pool of our experienced software engineers, graphics designers, and other ICT related staff for your organization. Just hit the hire a developer button and the admin will communicate back to you.

                        </p>

                           
                        </div>

                    

                    </div>
                    

                </div>

            </div>

        </div>
            
        </div>
    )
}


export default HomePage;