import ImgBk from "../../../Images/imgone.gif"
import Style from "./introduction.module.css"

function Introduction(){
    return(
        <div className={Style.main}>

            <div className={Style.imag}>
                <img src={ImgBk} alt="" />
            </div>
            <div className={Style.txt}>
                <div className={Style.pone}>
                <p className={Style.pone}>Kickstart Your Tech Career  Today!</p>
                </div>
                
                <p>Let us help you begin an amazing journey to becoming a professional software developer and land your first tech job within 6 months!</p>

               
            </div>
            <div className={Style.btnhire} >
                    <button> Hire a Developer</button><span>OR</span>  
                    <button> Get a website</button>
                </div>
            
        </div>
    )
}


export default Introduction;