
import {Link} from 'react-router-dom'
import Style from './learning.module.css'
import LearningImg from '../../../Images/teach.jpg'
import LearningImgTwo from "../../../Images/Work from home tips.png"
import LanguageImg from '../../../Images/animone.gif'

// Learning
import ReactImg  from "../../../Images/react.png"
import JavascriptImg  from "../../../Images/JavaScriptlogo1.png"
import PythonImg  from "../../../Images/Python.jpg"
import NodeImg  from "../../../Images/nodejs image.png"

import FullStack  from "../../../Images/nodejs image.png"
import FrontEnd  from "../../../Images/frontend.jpg"
import BackEnd  from "../../../Images/Backend.jpeg"
import Desktop  from "../../../Images/Desktop.jpg"

function Learning(){
    return(
        <div className={Style.bodyCover}>
            <div className={Style.firstTxt}>
                {/* <h1>Why Join Ekemiben Tech Community?</h1> */}
                <h1>Why Join Ekemiben Sofware Engineering?</h1>
                <p className={Style.subjoin}>Learning alone could be so tedious, our students collaborate with each other learn and develop real life application before the completion of their programm. At the end of the your program you will</p>
            </div>
            
            <div className={Style.learningMain}>
                <div className={Style.flexcolumn}>
                    <div className= {Style.learning}>
                        <img src={LearningImg} alt="Learning pix" />
                    </div>
                    <div className={Style.belowImg}>
                    <hr></hr>
                        <p> 
                    Six months intensive training to become a fullstack software engineer
                    </p>
                    </div>
                </div>
                <div className={Style.flexcolumn}>
                    <div className= {Style.learning}>
                        <img src={LearningImgTwo} alt="Learning pix" />
                    </div>
                    <div className={Style.belowImg}>
                    <hr></hr>
                        <p>
                        Code independently after six months of rigorouse and intensive training by team of sofware engineer experts. solidify your skills with take home project and be ready for remote/onsite job
                        </p>
                    </div>
                </div>
            </div>
{/* Last part for this component */}
        <div className={Style.programmingtracks}>
            <div className={Style.imgContainer}>
                <p>Some of Our Courses That Will Make You Standout </p>
                
                <div className={Style.languages}>
                    <div className={Style.prog}>
                    <img src={JavascriptImg} alt="" />
                    <br></br>
                    {/* <hr></hr> */}
                       <p>JavaScript</p> 
                    </div>

                    
                    <div className={Style.prog}>
                    <img src={ReactImg} alt="" />
                    <br></br>
                    {/* <hr></hr>  */}
                    <p>React </p>
                    </div>


                    <div className={Style.prog}>
                    <img src={NodeImg} alt="" />
                    <br></br>
                    {/* <hr></hr> */}
                       <p> Node.Js</p>
                    </div>

                    <div className={Style.prog}>
                    <img src={PythonImg} alt="" />
                    {/* <hr></hr> */}
                    <br></br>
                    
                       <p>Python</p> 
                    </div>
                </div>


                <div className={Style.tracks}>
                    <div className={Style.prog}>
                    <img src={FrontEnd} alt="" />
                    <br></br>
                    {/* <hr></hr> */}
                       <p>Frontend</p> 
                    </div>

                    
                    <div className={Style.prog}>
                    <img src={BackEnd} alt="" />
                    <br></br>
                    {/* <hr></hr>  */}
                    <p>Backend </p>
                    </div>


                    <div className={Style.prog}>
                    <img src={FullStack} alt="" />
                    <br></br>
                    {/* <hr></hr> */}
                       <p> Fullstack</p>
                    </div>

                    <div className={Style.prog}>
                    <img src={Desktop} alt="" />
                    {/* <hr></hr> */}
                    <br></br>
                    
                       <p>Desktop App</p> 
                    </div>
                   
                </div>
                <Link to="/cards">
                <div className={Style.btnapply}><button>Apply</button></div>
                </Link>
            </div>   
        </div>
        </div>
    )
}
export default Learning