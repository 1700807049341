import React from 'react'
import BioScreenStyle from './BioScreen.css'
import {Link} from 'react-router-dom';

const BioScreen = ({user}) => {
  return (
   <article className = 'bioscreen'>
    <Link to ={`/professional/${user.id}`}>
        <h2>{user.img}</h2>
        <p>{user.bioinfo}</p>
    </Link>
   </article>
  )
}

export default BioScreen