
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
// import { useFormik } from "formik";
import { Link } from 'react-router-dom';
import HireStyle from './hiredev.module.css'
import BgImage from "../../../Images/edit.jpg"
import Fade from 'react-reveal/Zoom'

// import "bootstrap/dist/css/bootstrap.min.css"
import {useState} from 'react';
import axios from 'axios';
import {toast, ToastContainer} from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';





const onSubmit = values => {
  console.log("Form data", values)
}
const validate = values => {
 
   
}

const Hiredev = (ev) => {

    // Form submission function
    const [companyname, setCompanyname] = useState("");
    const [location, setLocation] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [joblocation, setJoblocation] = useState("");
    // const [jobtype, setJobtype] = useState("");
    const [developertype, setDevelopertype] = useState("");
    const [jobduration, setJobduration] = useState("");
    const [optionaltextdes, setOPtionaltextdes] = useState("");
    const [loading, setLoading] = useState(false)




    function hompage (){
      return window.location = '/'
     }
 
     function btnloading(){
       setLoading(true)
       // Button.disabled(true)
     }
     function btnunload(){
       setLoading(false)
       //  Button.disabled(true)
     }


    // function for submission
    const handleFormSubmit = async (event) =>{
      event.preventDefault();
      console.log(event)

      if(!companyname || !phone || !email){
             
        return toast.error("Please fill all the input boxes correctly", setTimeout(btnloading, 100) , setTimeout(btnunload, 7000))
      }

      try{
        const response = await axios.post("https://my-learning-site.cyclic.app/api/hiredeveloper", {
          // const response = await axios.post("http://localhost:5000/api/hiredeveloper",{
          companyname,
          location,
          email,
          phone,
          joblocation,
          // jobtype,
          developertype,
          jobduration,
          optionaltextdes

        })
        
          setLoading(false);
          toast.success('You submission is successful, the admin will contact you soon.', setTimeout(btnloading, 100) ,setTimeout(hompage, 6000))
          // toast.success(data.message)
        
        //console.log("Form submitted successfully!")
      }catch(err){
        console.log("The form data was not sent")
      }
    }




   

  return (
    <Fade cascade>
      
      <div className={HireStyle.main}>
      <ToastContainer position = "bottom-center" limit ={1}/>
        <img src={BgImage} alt="" />
        
          <form onSubmit={handleFormSubmit} className={HireStyle.bgform}>
            <div className={HireStyle.formcontent}>

            
             
              <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                <label htmlFor="name"></label>
                <Form.Control type="text" id="name" name="name" placeholder='Company name ...' 
             onChange={event =>setCompanyname(event.target.value)} />
             </Form.Group>
                 

             <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                <label htmlFor="location"></label>
                <Form.Control type="text" id="location" name="location" placeholder='Location...' 
                onChange={event =>setLocation(event.target.value)} />
              </Form.Group>
          


              <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                <label htmlFor="email"></label>
                <Form.Control type="email" id="email" name="email" placeholder='Email address...'  onChange={event =>setEmail(event.target.value)} />
              </Form.Group>


              <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                <label htmlFor="phone"></label>
                <Form.Control type="tel" id="phone" name="phone" placeholder='Phone number...'onChange={event =>setPhone(event.target.value)} />
              </Form.Group>


              <div className={HireStyle.formControl}>
                <label htmlFor="channel"></label>
                <Form.Select size="lg" className='plans' id='plan' onChange={event=>setJoblocation(event.target.value)} className={HireStyle.selectGrayFont}>
                  <option > Job Location</option>
                  <option value = 'fulltime'>Remote</option>
                  <option value = 'parttime'>Onsite</option>
                  <option value = 'parttime'>Hybride</option>
                </Form.Select>
              </div>


              <div className={HireStyle.formControl}>
                <label htmlFor="channel"></label>
                <Form.Select size="lg" className='plans' id='plan' onChange={event=>setDevelopertype(event.target.value)} className={HireStyle.selectGrayFont}>
                  <option >Developer Stack</option>
                  <option value = 'frontend'>Frontend</option>
                  <option value = 'backend'>Backend</option>
                  <option value = 'fulstack'>Fullstack</option>
                  <option value = 'desktop'>Desktop App.</option>
                  <option value = 'other' >Others</option>
                 
                </Form.Select>
              
              </div>

              <div className={HireStyle.formControl}>
                <label htmlFor="channel"></label>
                <Form.Select size="lg" className='plans' id='plan' onChange={event=>setJobduration(event.target.value)} className={HireStyle.selectGrayFont}>
                  <option > Job Duration</option>
                  <option value = 'fulltime'>Full Time</option>
                  <option value = 'parttime'>Part Time</option>
                  <option value = 'contract'>Contract</option>
                </Form.Select>
               
              </div>

          
              <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                <label htmlFor="description"></label>
                <Form.Control as="textarea" rows={3} className={HireStyle.textArea}  placeholder='Dscription... ' 
                onChange={event=>setOPtionaltextdes(event.target.value)}/>
              </Form.Group>

              {/* <div className={HireStyle.button}> <Link to='/successful'></Link>
                <button type="submit">Submit</button>          
              </div> */}
              <div className="d-grid gap-2">
                <Button variant="primary" size="lg" type="submit" disabled ={loading}>Submit</Button>
                 </div>
            </div>

        
          </form>

        </div>
    </Fade>
  )
}

export default Hiredev